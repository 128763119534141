.root {
	display: flex;
	flex-direction: column;
	max-width: 570px;

	gap: 40px;

	.title-container {
		display: flex;
		flex-direction: column;
		gap: 24px;
	}

	.content {
		width: 54%;
		display: flex;
		flex-direction: column;
		gap: 40px;

		.button-container {
			display: flex;
			flex-direction: column;
			gap: 8px;
		}
	}
}
