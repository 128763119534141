.root {
	width: 484px;
	display: flex;
	flex-direction: column;
	gap: 32px;

	.fields-container {
		display: flex;
		flex-direction: column;
		padding-right: 96px;
		gap: 16px;

		.address-fields {
			display: flex;
			flex-direction: column;
			margin: 0 auto;
			gap: 8px;

			width: 90%;
		}
	}
}
