.root {
	.header {
		display: flex;
		justify-content: space-between;
		margin-bottom: 40px;

		.header-title {
			display: flex;
			flex-direction: column;
			gap: 8px;
		}
	}

	.informations-title {
		margin-bottom: 24px;
	}

	.informations-container {
		display: flex;
		flex-direction: column;
		gap: 8px;
		margin-bottom: 40px;
	}

	.button {
		display: flex;
		gap: 8px;
		margin-top: 24px;
	}
}
