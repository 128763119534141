.root {
	max-width: 220px;
	width: 100%;
	height: max-content;
	display: flex;
	padding: 16px;
	flex-direction: column;
	gap: 8px;
	background-color: var(--Warning-50);
	box-sizing: border-box;

	.title-container {
		display: flex;
		align-items: center;
		gap: 6px;

		.circle {
			flex: 1;
			min-width: 12px;
			min-height: 12px;
			max-width: 12px;
			max-height: 12px;
			border-radius: 50%;
			background-color: var(--Warning-500);
		}

		.validated {
			background-color: var(--Success-500);
		}

		.error {
			background-color: var(--Error-500);
		}
	}
}

.validated {
	background-color: var(--Success-50);
}

.error {
	background-color: var(--Error-50);
}
