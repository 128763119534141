.root {
	display: flex;

	.left-side-menu {
		position: fixed;
		height: 100vh;
	}

	.body {
		width: 100%;

		.content {
			padding: 16px 24px 24px 16px;
		}
	}
}
