.root {
    .delete-icon {
        cursor: pointer;
        width: 20px;
        height: 20px;
    }

    .delete-modal {
        display: flex;
        flex-direction: column;
        gap: 32px;

        .button-container {
            display: flex;
            gap: 24px;
        }
    }
}