.root {
	width: 484px;
	display: flex;
	flex-direction: column;
	gap: 48px;

	&.hidden {
		visibility: hidden;
		position: absolute;
		pointer-events: none;
		width: 0;
		height: 0;
		overflow: hidden;
	}

	.beneficiaries {
		display: flex;
		flex-direction: column;
		gap: 24px;
	}
}

.add-beneficary{
	display: flex;
	flex-direction: column;
	gap: 16px;
}