.root {
	.subscription-header {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		gap: 16px;
		padding: 8px 0;
		margin: 0px 0 40px 0;

		.information-container {
			display: flex;
			align-items: center;
			gap: 32px;

			.subscription-information {
				display: flex;
				gap: 20px;

				.collumn {
					display: flex;
					flex-direction: column;
					gap: 8px;

					&.left {
						max-width: 165px;
					}
				}
			}

			.statut-input {
				width: 224px;
				padding-left: 32px;
				border-left: 1px solid var(--Wild-Sand-200, #dcdcdc);
			}
		}
	}
}
