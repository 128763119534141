@import "themes/constants.module.scss";
@import "themes/modes.scss";

.root {
	nav {
		display: flex;

		.link {
			cursor: pointer;
			padding: 16px;
			text-decoration: none;
			color: var(--Wild-Sand-700);
			border-bottom: 1px solid var(--Wild-Sand-200);

			&:hover {
				color: var(--Wild-Sand-800);
				border-color: var(--Wild-Sand-300);
			}

			&:active {
				color: var(--Wild-Sand-900);
				border-color: var(--Wild-Sand-400);
			}

			&.active {
				color: var(--Wild-Sand-950);
				border-color: var(--Opium-600);
			}
		}
	}

	.content {
		margin-top: 24px;
	}
}
