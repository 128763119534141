.container {
	width: 100%;

	.title-container {
		display: flex;
		justify-content: space-between;
		gap: 16px;

		.title {
			margin-bottom: 16px;
		}

		.icon-container {
			display: flex;
			align-items: center;
			gap: 16px;

			svg {
				cursor: pointer;
			}
		}
	}

	.root {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 16px;
		padding: 32px;
		background-color: var(--white);
		border: 1px dashed var(--Wild-Sand-400, #989898);

		height: fit-content;

		&[data-drag-over="true"] {
			border: 1px dashed var(--grey);
		}

		&.uploaded {
			border: 1px dashed var(--Success-500);
		}

		.top-container {
			display: flex;
			align-items: center;

			.left {
				margin-right: 28px;
			}

			.separator {
				background-color: #939393;
				width: 1px;
				align-self: stretch;
			}

			.right {
				margin-left: 18px;

				.validated {
					color: var(--green-flash);
				}

				.refused-button {
					font-size: 14px;
					color: var(--re-hover);
					margin-left: 8px;
				}

				.title {
					display: flex;
					align-items: center;
					gap: 8px;
				}
			}
		}

		.files-name-container {
			width: 100%;
			display: flex;
			flex-direction: column;
			gap: 16px;

			.file-name-container {
				display: flex;
				align-items: center;
				justify-content: space-between;
				gap: 16px;

				.cross {
					cursor: pointer;
					width: 20px;
					height: 20px;
				}
			}
		}

		.bottom-container {
			display: flex;
			margin-top: 16px;

			.add-button {
				margin: auto;

				.add-document {
					display: flex;
					align-items: center;
					gap: 14px;
				}
			}
		}

		.text {
			margin-bottom: 12px;
		}
	}

	.modal-content {
		display: flex;
		flex-direction: column;
		gap: 16px;
	}

	.error-message {
		color: var(--red-flash);
		margin-top: 8px;
	}
}
