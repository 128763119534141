.root {
	width: 437px;
	display: flex;
	flex-direction: column;
	gap: 32px;

	.button-container {
		display: flex;
		justify-content: center;
		gap: 16px;
	}
}
