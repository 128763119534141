.root {
	display: flex;
	flex-direction: column;
	gap: 8px;

	.input-label {
		margin-bottom: 16px;
	}

	.radio-element {
		display: flex;
		align-items: baseline;
		gap: 8px;
		border: none;

		.content {
			display: flex;
			flex-direction: column;
			gap: 4px;
			cursor: pointer;
		}

		.content .label {
			color: var(--Wild-Sand-900);
		}

		&:hover .content .label {
			color: var(--Wild-Sand-950);
		}

		.radio {
			cursor: pointer;
			&:checked + .content .label {
				color: var(--Wild-Sand-950);
			}
		}

		&:focus-visible {
			outline: none;
		}
	}

	.errors-container {
		display: flex;
		gap: 20px;
		background-color: var(--Error-200);
		padding: 8px;
		align-items: center;
		.errors {
			display: flex;
			flex-direction: column;
			gap: 4px;
			color: var(--Error-800);
		}
	}

	&.hidden {
		visibility: hidden;
		position: absolute;
		pointer-events: none;
		width: 0;
		height: 0;
		overflow: hidden;
	}
}
