.root {
	display: flex;
	align-items: center;
	gap: 16px;
	padding: 12px 8px;
	text-decoration: none;
    
	background-color: var(--Wild-Sand-50);

	&:hover {
		background-color: var(--Wild-Sand-100);
	}

	&.active {
		background-color: var(--Wild-Sand-200);
	}

	svg {
		width: 24px;
		height: 24px;
		stroke: var(--Wild-Sand-950);
	}
}
