.root {
	display: flex;
	flex-direction: column;
	gap: 8px;

	.errors-container {
		display: flex;
		gap: 20px;
		background-color: var(--Error-200);
		padding: 8px;
		align-items: center;

		.errors {
			display: flex;
			flex-direction: column;
			gap: 4px;
			color: var(--Error-800);
		}
	}

	.hidden-input {
		display: none;
	}

	&.hidden {
		visibility: hidden;
		position: absolute;
		pointer-events: none;
		width: 0;
		height: 0;
		overflow: hidden;
	}
}
