.root {
	width: 484px;
	margin-bottom: 80px;

	.form {
		display: flex;
		flex-direction: column;
		gap: 32px;
	}

	.description {
		text-align: left;
		margin-bottom: 32px;
	}

	.footer {
		display: flex;
		justify-content: center;
		gap: 24px;
		padding: 32px 0;
	}
}
