.root {
	display: flex;
	gap: 32px;

	.step-decoration {
		position: relative;

		.step-circle {
			margin-top: 21px;
			min-width: 10px;
			min-height: 10px;
			max-width: 10px;
			max-height: 10px;
			border-radius: 50%;

			&.active {
				background-color: var(--Opium-400);
			}

			&.done {
				background-color: var(--Opium-600);
			}

			&.inactive {
				background-color: var(--Opium-200);
			}

			&.top::after {
				content: "";
				position: absolute;
				z-index: -1;
				bottom: 0;
				left: 50%;
				transform: translateX(-50%);
				width: 2px;
				height: calc(100% - 21px);
				background-color: var(--Wild-Sand-200);
			}

			&.middle::after {
				content: "";
				position: absolute;
				z-index: -1;
				top: 0;
				left: 50%;
				transform: translateX(-50%);
				width: 2px;
				height: 100%;
				background-color: var(--Wild-Sand-200);
			}

			&.bottom::after {
				content: "";
				position: absolute;
				z-index: -1;
				top: 0;
				left: 50%;
				transform: translateX(-50%);
				width: 2px;
				height: 22px;
				background-color: var(--Wild-Sand-200);
			}
		}
	}

	.container {
		padding: 16px 0;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 24px;
		text-align: start;
		width: 100%;

		&.inactive {
			opacity: 0.5;
		}

		.tab-info-container {
			display: flex;
			flex-direction: column;
			gap: 16px;
			width: 100%;

			.info-container {
				display: flex;
				flex-direction: column;
				align-items: flex-start;

				.info {
					display: flex;
					width: 100%;
					align-items: center;
					justify-content: space-between;
					gap: 16px;

					.description {
						text-align: left;
						min-width: 254px;
					}

					.icon {
						min-width: 24px;
						min-height: 24px;
						max-width: 24px;
						max-height: 24px;

						&.done {
							color: var(--Success-700);
						}

						&.pending {
							color: var(--Warning-700);
						}

						&.error {
							color: var(--Error-700);
						}
					}
				}
			}
		}
	}
}
