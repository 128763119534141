.root {
	.separator {
		width: 100%;
		height: 2px;
		background-color: var(--Wild-Sand-600);
	}

	.top-separator {
		margin-bottom: 24px;
	}

	.bottom-separator {
		margin-top: 24px;
	}

	.children {
		margin-top: 24px;
		display: flex;
		flex-direction: column;
		gap: 24px;
	}
}
