.root {
	.civility-radio-input {
		margin: 24px 0;
	}

	.form {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		gap: 16px;
	}
}
