.root {
	.label {
		align-self: flex-start;
	}
	.inputs-container {
		.errors-container {
			display: flex;
			gap: 20px;
			background-color: var(--Error-200);
			padding: 8px;
			align-items: center;

			.errors {
				display: flex;
				flex-direction: column;
				gap: 4px;
				color: var(--Error-800);
			}
		}

		&.hidden {
			visibility: hidden;
			position: absolute;
			pointer-events: none;
			width: 0;
			height: 0;
			overflow: hidden;
		}
		display: flex;
		flex-direction: column;
		gap: 24px;

		margin-top: 32px;
		.input {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			align-items: center;
			gap: 16px;

			.percentage-container {
				position: relative;
				.percentage {
					position: absolute;
					display: flex;
					align-items: center;
					top: 0;
					right: 8px;
					bottom: 0;
				}
			}
		}
	}
	.separator {
		margin-top: 32px;
		width: 100%;
		height: 2px;
		background-color: var(--Wild-Sand-300);
	}
}
