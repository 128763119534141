.root {
	.form {
		display: flex;
		flex-direction: column;
		gap: 32px;

		max-width: 335px;

		.funds-origin {
			display: flex;
			flex-direction: column;
			gap: 16px;
		}
	}
}
