.root {
	display: flex;
	gap: 76px;

	.container {
		display: flex;
		flex-direction: column;
		gap: 40px;

		.fields {
			display: flex;
			flex-direction: column;
			gap: 16px;
			max-width: 335px;
		}

		.button-container {
			display: flex;
			justify-content: space-between;
			gap: 16px;
		}

	}

	.seal-main-container {
		display: flex;
		flex-direction: column;
		gap: 40px;

		.seal-info-message {
			width: 319px;
		}

		.seal-container {
			padding: 16px;
			width: fit-content;
			border: 1px solid var(--Wild-Sand-100, #EFEFEF);
			background: var(--Wild-Sand-50, #F6F6F6);
		}
	}
}