.root {
	display: flex;
	flex-direction: column;
	gap: 16px;

	.errors-container {
		display: flex;
		gap: 20px;
		background-color: var(--Error-200);
		padding: 8px;
		align-items: center;

		.errors {
			display: flex;
			flex-direction: column;
			gap: 4px;
			color: var(--Error-800);
		}
	}

	.header {
		display: grid;
		grid-template-columns: 1fr 1fr;
	}

	.input-container {
		display: grid;
		grid-template-columns: 1fr 1fr 20px;
		align-items: center;
		gap: 16px;

		svg {
			width: 20px;
			height: 20px;
			cursor: pointer;
		}
	}
}
