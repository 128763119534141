.root {
	display: flex;
	flex-direction: column;
	gap: 24px;
	width: 335px;

	.input-container {
		display: flex;
		flex-direction: column;
		gap: 16px;
		padding: 16px;
		border-radius: 4px;
		border: 1px solid var(--Wild-Sand-400, #989898);
		position: relative;

		.address-fields {
			display: flex;
			flex-direction: column;
			margin: 0 auto;
			gap: 8px;
			width: 90%;
		}
	}
}
