.root {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 32px;

	.resend-modal {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 32px;

		.description {
			max-width: 393px;
			text-align: center;
		}

		.button-container {
			display: flex;
			gap: 24px;
		}
	}
}
