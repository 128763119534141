.root {
	height: 83px;
	border-bottom: 1px solid #dcdcdc; // TODO vars
	padding: 8px 16px;
	display: flex;
	justify-content: space-between;
	align-items: center;

	.left-side {
		display: flex;
		align-items: center;
		gap: 16px;
	}

	.right-side {
		float: right;
	}
}
