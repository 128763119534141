.root {
	display: flex;
	flex-direction: column;
	gap: 24px;
	.row {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 76px;
		.col {
			flex-direction: column;
			display: flex;
			gap: 24px;
		}
	}
}
