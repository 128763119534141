.root {
	.container {
		display: flex;
		flex-direction: column;
		gap: 16px;

		.label-container {
			display: flex;
			flex-direction: column;
			gap: 8px;
			white-space: nowrap;

			.label {
				color: var(--Wild-Sand-900);
			}
		}

		.radio-element {
			display: flex;
			flex-direction: column;
			gap: 16px;
			border: 1px solid var(--Wild-Sand-200);
			padding: 16px;

			.input-container {
				display: flex;
				align-items: baseline;
				gap: 8px;
				white-space: nowrap;

				label .text {
					cursor: pointer;
					color: var(--Wild-Sand-900);
				}
			}

			&:hover label .text {
				color: var(--Wild-Sand-950);
			}

			input {
				cursor: pointer;
				&:checked + label .text {
					color: var(--Wild-Sand-950);
				}
			}

			&:focus-visible {
				outline: none;
			}

			.content {
				display: flex;
				flex-direction: column;
				gap: 16px;

				height: 0;
				pointer-events: none;
				overflow: hidden;
				position: absolute;

				.inputs {
					display: flex;
					flex-direction: column;
					gap: 16px;
					margin-top: 8px;
				}

				&.checked {
					height: auto;
					pointer-events: auto;
					overflow: visible;
					position: relative;
				}
			}
		}

		.errors-container {
			display: flex;
			gap: 20px;
			background-color: var(--Error-200);
			padding: 8px;
			align-items: center;
			.errors {
				display: flex;
				flex-direction: column;
				gap: 4px;
				color: var(--Error-800);
			}
		}

		&.hidden {
			visibility: hidden;
			position: absolute;
			pointer-events: none;
			width: 0;
			height: 0;
			overflow: hidden;
		}
	}

	.use-same-rib-container {
		margin-top: 16px;
	}
}
