.root {
	display: flex;
	flex-direction: column;
	gap: 32px;

	.form {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		gap: 16px;
	}
}
