.root {
	display: flex;
	flex-direction: column;
	gap: 32px;

	a {
		text-decoration: none;
		color: var(--color-generic-black);
	}
}
