.root {
    display: flex;
    flex-direction: column;
    padding: 32px 140px;
    gap: 32px;

    .back-button {
        width: fit-content;

        .icon {
            path {
                stroke: var(--Wild-Sand-900, #292929);
            }
        }
    }

    .title {
        text-align: center;
    }

    .table {
        padding-bottom: 80px;
    }

    .footer {
        display: flex;
        justify-content: center;
        gap: 24px;
        position: fixed;
        bottom: 0px;
        left: 0;
        right: 0;
        padding: 32px 0;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%);
    }
}

.modal {
    display: flex;
    flex-direction: column;
    gap: 32px;

    .button-container {
        display: flex;
        gap: 24px;
    }
}

.subtitle {
    text-align: center;
}