.root {
	width: 484px;
	.form {
		display: flex;
		flex-direction: column;
		gap: 32px;

		.fields-container {
			display: flex;
			flex-direction: column;
			padding-right: 96px;
			gap: 16px;

			.address-fields{
				display: flex;
				flex-direction: column;
				margin: 0 auto;
				gap: 8px;

				width: 360px;
			}
		}

		.button {
			margin: 16px auto 48px auto;
		}

		.message-content {
			a {
				display: flex;
				align-items: center;
				gap: 8px;
				text-decoration: none;

				svg {
					path {
						stroke: var(--color-generic-black);
					}
				}
			}
		}
	}
}
