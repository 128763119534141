.root {
	.infos-container {
		margin-top: 24px;
		display: flex;
		gap: 76px;

		.col {
			flex: 1;
			display: flex;
			flex-direction: column;
			gap: 16px;
		}
	}

	.button-container {
		display: flex;
		gap: 16px;
	}

	button {
		margin-top: 24px;
	}
}
