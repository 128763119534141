.root {
	.form {
		width: 404px;
		display: flex;
		flex-direction: column;
		gap: 32px;

		.button-container {
			display: flex;
			justify-content: center;
			gap: 16px;
		}
	}
}
