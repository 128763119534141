.root {
	.form-container {
		display: flex;
		flex-direction: column;
		gap: 48px;
		max-width: 335px;

		.form {
			display: flex;
			flex-direction: column;
			gap: 32px;

			.fields {
				display: flex;
				flex-direction: column;
				gap: 16px;
			}
		}
	}
}
