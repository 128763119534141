.root {
    .subtitle {
        text-align: center;
        margin: 24px 0;
    }

    .table-container {
        display: flex;
        flex-direction: column;
        gap: 56px;

        .separator {
            height: 1px;
            width: 100%;
            background-color: var(--Wild-Sand-200, #DCDCDC);
        }
    }

    .total-container {
        text-align: right;
        width: fit-content;
        margin-top: 24px;
        margin-left: auto;

        .total-label {
            padding: 12px 16px;
            background-color: var(--Wild-Sand-900, #292929);
            color: white;
        }

        .total-number {
            color: white;
            padding: 16px;
            background: var(--Wild-Sand-700, #525252);
        }
    }

    .button-container {
        position: fixed;
        width: 100%;
        bottom: 40px;
        display: flex;
        justify-content: center;
        gap: 24px;
    }

}