@import "themes/constants.module.scss";

:root {
	--content-max-width: 2160px;
	--template-margin: auto;
	--content-padding: 32px;

	--font-primary: "Abril Fatface", serif;
	--font-secondary: "Droid Sans", sans-serif;
}

body {
	--screen-xl: $screen-xl;
	--screen-lg: $screen-lg;
	--screen-md: $screen-md;
	--screen-sm: $screen-sm;
	--screen-xs: $screen-xs;

	// Get screen size in real-time
	@media (max-width: $screen-xs) {
		--screen-size: screen-xs;
	}
	@media (min-width: $screen-xs) and (max-width: $screen-sm) {
		--screen-size: screen-sm;
	}
	@media (min-width: $screen-sm) and (max-width: $screen-md) {
		--screen-size: screen-md;
	}
	@media (min-width: $screen-md) and (max-width: $screen-lg) {
		--screen-size: screen-lg;
	}
	@media (min-width: $screen-lg) and (max-width: $screen-xl) {
		--screen-size: screen-xl;
	}
}
