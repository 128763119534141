.root {
	max-width: 1200px;

	.due-date-container {
		display: grid;
		grid-template-columns: 1fr 1fr 4fr;
		align-items: center;
		gap: 16px;
	}
}
