.modal {
    display: flex;
    flex-direction: column;
    gap: 32px;
    align-items: center;

    .button-container {
        display: flex;
        gap: 24px;
    }
}