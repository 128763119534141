.root {
    display: flex;
    gap: 32px;

    .left-part {
        display: flex;
        flex-direction: column;
        gap: 32px;
        flex: 1;

        .form {
            max-width: 449px;
            display: flex;
            flex-direction: column;
            gap: 32px;
        }

    }

    .right-part {
        display: flex;
        flex-direction: column;
        gap: 16px;
        flex: 1
    }

    .separator {
        width: 1px;
        border-right: 1px solid #E5E5E5;
    }

    .delete-modal {
        display: flex;
        flex-direction: column;
        gap: 32px;

        .button-container {
            display: flex;
            gap: 24px;
        }
    }
}