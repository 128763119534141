.root {
	display: flex;
	flex-direction: column;
	gap: 24px;

	.radio {
		margin-bottom: 48px;
	}

	&.hidden {
		visibility: hidden;
		position: absolute;
		pointer-events: none;
		width: 0;
		height: 0;
		overflow: hidden;
	}
}
