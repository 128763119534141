.root {
	display: flex;
	align-items: center;

	.step-indicator {
		circle {
			fill: var(--Opium-200);
		}
		&.active circle {
			fill: var(--Opium-400);
		}
		&.done circle {
			fill: var(--Opium-600);
		}
	}
}
