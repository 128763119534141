.root {
	max-width: 880px;
	margin: auto;

	.subtitle {
		text-align: center;
		margin-bottom: 32px;
	}

	.uploaders {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 32px;
		margin-bottom: 32px;

		.row {
			display: flex;
			gap: 72px;
		}
	}

	.text {
		margin: 32px 0;
	}

	.form {
		.footer {
			display: flex;
			justify-content: center;
			gap: 24px;
			padding: 32px 0;
		}
	}
}