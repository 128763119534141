.root {
	display: flex;

	.body {
		display: flex;
		min-height: 100vh;
		width: 100%;

		.stepper-left {
			width: 276px;
			max-width: 276px;
			min-width: 276px;
			position: relative;
			padding: 24px;
			background: var(--Wild-Sand-50);

			.stepper-container {
				position: sticky;
				display: flex;
				flex-direction: column;
				gap: 40px;
				justify-content: center;

				.step {
					display: flex;
					align-items: center;
					gap: 16px;
					text-decoration: none;
					padding: 12px 8px;
					border: 1px solid transparent;
					white-space: nowrap;

					&[data-selected="true"] {
						border: 1px solid var(--Wild-Sand-200);
						background: var(--Wild-Sand-100);

						.step-title {
							color: var(--Wild-Sand-950);
							font-weight: bold;
						}

						.step-number {
							font-weight: bold;
						}
					}

					&[data-completed="true"] {
						.step-number {
							background: var(--Success-50);
							border: 1px solid var(--Success-700);
							color: var(--Success-700);
						}
					}

					.step-title {
						color: var(--Wild-Sand-600);
					}

					.step-number {
						display: flex;
						align-items: center;
						justify-content: center;
						border: 1px solid black;
						padding: 8px;
						width: 8px;
						height: 8px;
						border-radius: 100px;
						line-height: 26px;
					}
				}
			}

			.validation-container {
				position: fixed;
				display: flex;
				justify-content: center;
				width: 276px;
				bottom: 24px;
			}
		}

		.content-right {
			flex: 1;
			padding: 40px 32px;

			.title {
				text-align: left;
				margin: 32px 0;
			}
		}
	}
}
