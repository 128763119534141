.form {
	max-width: 515px;
	margin-top: 24px;

	.form-container {
		display: flex;
		gap: 24px;
		flex-direction: column;
	}

	.button-container {
		display: flex;
		justify-content: flex-start;
		gap: 24px;
		margin: auto;
		margin-top: 48px;
	}
}
