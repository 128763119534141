.root {
	text-decoration: none;
	.icon {
		width: 20px;
		height: 20px;

		path {
			stroke: var(--Wild-Sand-900);
		}
	}
}
