.root {
	display: flex;
	flex-wrap: wrap;
	gap: 40px;

	.file-container {
		position: relative;
		display: flex;
		flex-direction: column;
		gap: 40px;

		object {
			height: 400px;
			width: 400px;
			object-fit: cover;
			object-position: top;
		}

		a {
			position: absolute;
			bottom: 0;
			right: 0;
		}
	}
}
