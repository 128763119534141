.errors-container {
	display: flex;
	gap: 20px;
	background-color: var(--Error-200);
	padding: 8px;
	align-items: center;
	.errors {
		display: flex;
		flex-direction: column;
		gap: 4px;
		color: var(--Error-800);
	}
}
