.root {
	display: flex;
	flex-direction: column;
	gap: 32px;

	.form {
		display: flex;
		flex-direction: column;
		gap: 32px;
	}

	.button-container {
		display: flex;
		gap: 8px;
	}
}
