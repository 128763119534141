.root {
	display: flex;
	flex-direction: column;
	gap: 8px;

	&.readonly {
		pointer-events: none;
	}

	&.hidden {
		visibility: hidden;
		position: absolute;
		pointer-events: none;
		width: 0;
		height: 0;
		overflow: hidden;
	}

	.label-container {
		display: flex;
		align-items: center;
		gap: 8px;
	}

	.options {
		display: flex;
		gap: 8px;
		align-items: "center";

		.content {
			display: flex;
			flex-direction: column;

			span {
				display: flex;
			}
		}
	}
}
