.root {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 56px;

	.left {
		display: flex;
		flex-direction: column;
		gap: 32px;

		text-align: left;

		.requirement-container {
			display: flex;
			flex-direction: column;
			gap: 16px;
			
			.requirement {
				display: flex;
				align-items: center;
				gap: 16px;

				svg {
					width: 24px;
					height: 24px;
				}
			}
		}
	}

	.right {
		display: flex;
		flex-direction: column;
		gap: 32px;

		.fields {
			display: flex;
			flex-direction: column;
			gap: 16px;
			max-width: 335px;
		}
	}

	.button-container {
		display: flex;
		flex-direction: column;
		gap: 8px;
	}
}
