.root {
	display: flex;
	.top-recap {
		position: sticky;
		top: 32px;
		bottom: 32px;
		right: 24px;
		padding: 24px;
		background: var(--Wild-Sand-50);
		width: 336px;
		height: fit-content;
		max-height: calc(100vh - 128px);
		overflow: auto;
		display: flex;
		flex-direction: column;
		gap: 24px;

		.separator {
			width: 100%;
			height: 2px;
			background: var(--color-generic-black);
		}

		.products {
			display: flex;
			flex-direction: column;
			gap: 16px;
			.product {
				display: flex;
				flex-direction: column;
				gap: 4px;
				.product-header {
					display: flex;
					justify-content: space-between;
				}

				.product-infos {
					display: flex;
					justify-content: space-between;
				}

				.product-separator {
					margin-top: 12px;
					width: 100%;
					height: 1px;
					background: var(--Wild-Sand-200);
				}
			}
		}
	}
	.body {
		display: flex;
		min-height: 100vh;
		width: 100%;

		.stepper-left {
			width: 250px;
			max-width: 250px;
			min-width: 250px;
			position: relative;
			padding: 40px 24px;
			background: var(--Wild-Sand-50);

			.stepper-container {
				position: sticky;
				display: flex;
				flex-direction: column;
				gap: 40px;
				justify-content: center;
				top: 24px;
				left: 24px;
				right: 24px;

				.back-button {
					.back-icon {
						path {
							stroke: black;
						}
					}
				}

				.step {
					display: flex;
					align-items: center;
					gap: 16px;
					text-decoration: none;
					padding: 12px 8px;
					border: 1px solid transparent;

					&[data-selected="true"] {
						border: 1px solid var(--Wild-Sand-200);
						background: var(--Wild-Sand-100);

						.step-title {
							color: var(--Wild-Sand-950);
							font-weight: bold;
						}

						.step-number {
							font-weight: bold;
						}
					}

					&[data-completed="true"] {
						.step-number {
							background: var(--Success-50);
							border: 1px solid var(--Success-700);
							color: var(--Success-700);
						}
					}

					.step-title {
						color: var(--Wild-Sand-600);
					}

					.step-number {
						display: flex;
						align-items: center;
						justify-content: center;
						border: 1px solid black;
						padding: 8px;
						width: 8px;
						height: 8px;
						border-radius: 100px;
						line-height: 26px;
					}
				}
			}

			.validation-container {
				position: fixed;
				display: flex;
				justify-content: center;
				width: 250px;
				bottom: 24px;
			}
		}

		.content-right {
			flex: 1;
			padding: 40px 32px;

			.title {
				text-align: left;
				margin: 32px 0;
			}
		}
	}
}

.modal-content {
	max-width: 402px;

	.modal-title {
		margin-bottom: 32px;
	}

	.modal-button {
		margin-top: 32px;
	}

	.modal-description {
		margin-bottom: 12px;
	}

	display: flex;
	flex-direction: column;
	gap: 32px;
	align-items: center;

	.button-container {
		display: flex;
		gap: 24px;
	}
}
