.root {
	.header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 40px;
	}

	.actions {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 24px;

		a {
			color: var(--color-primary);
			text-decoration: none;
		}

		svg {
			cursor: pointer;
			width: 20px;
			height: 20px;
		}
	}

}
