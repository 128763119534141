@import "themes/constants.module.scss";
@import "themes/modes.scss";

.root {
  padding: 10px 16px;
  border: 1px solid var(--Wild-Sand-100, #efefef);
  background: var(--Wild-Sand-50, #f6f6f6);
  overflow: hidden;

  .text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
  }
}