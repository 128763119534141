.root {
	background-color: #f6f6f6;
	width: 243px;
	height: 100%;
	display: flex;
	flex-direction: column;

	.logo {
		width: 100%;
		margin-top: 24px;
	}

	.container {
		display: flex;
		flex-direction: column;
		padding: 24px;
		justify-content: space-between;
		height: 100%;

		nav {
			display: flex;
			flex-direction: column;
			gap: 32px;
		}

		.bottom {
			display: flex;
			flex-direction: column;
			gap: 16px;
			.contact {
				display: flex;
				flex-direction: column;
				background-color: var(--Opium-300);
				gap: 14px;
				padding: 24px 16px;
                text-align: center;
			}
		}
	}
}
