.root {
    padding-bottom: 80px;

    .icon-container {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: help;

        .icon {
            width: 20px;
            height: 20px;
            margin: auto;
        }
    }
}