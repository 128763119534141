.root {
    margin-top: 32px;

    .form {
        display: flex;
        flex-direction: column;
        gap: 16px;
        max-width: 335px;

        .user-created {
            margin-top: 32px;
        }
    }
}