.root {
	display: flex;
	flex-direction: column;
	gap: 40px;

	.main-title-container {
		display: flex;
		justify-content: space-between;
		gap: 32px;
	}

	.info {
		display: flex;
		flex-direction: column;
		gap: 16px;

		.title {
			margin-bottom: 8px;
		}
	}
}
