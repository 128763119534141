@import "themes/constants.module.scss";
@import "themes/variables.scss";
@import "themes/fonts.scss";

body {
	--color-generic-white: #{$color-generic-white};
	--color-generic-black: #{$color-generic-black};

	--Wild-Sand-50: #{$Wild-Sand-50};
	--Wild-Sand-100: #{$Wild-Sand-100};
	--Wild-Sand-200: #{$Wild-Sand-200};
	--Wild-Sand-300: #{$Wild-Sand-300};
	--Wild-Sand-400: #{$Wild-Sand-400};
	--Wild-Sand-500: #{$Wild-Sand-500};
	--Wild-Sand-600: #{$Wild-Sand-600};
	--Wild-Sand-700: #{$Wild-Sand-700};
	--Wild-Sand-800: #{$Wild-Sand-800};
	--Wild-Sand-900: #{$Wild-Sand-900};
	--Wild-Sand-950: #{$Wild-Sand-950};

	--Opium-50: #{$Opium-50};
	--Opium-100: #{$Opium-100};
	--Opium-200: #{$Opium-200};
	--Opium-300: #{$Opium-300};
	--Opium-400: #{$Opium-400};
	--Opium-500: #{$Opium-500};
	--Opium-600: #{$Opium-600};
	--Opium-700: #{$Opium-700};
	--Opium-800: #{$Opium-800};
	--Opium-900: #{$Opium-900};
	--Opium-950: #{$Opium-950};

	--Red-logo-50: #{$Red-logo-50};

	--Success-50: #{$Success-50};
	--Success-100: #{$Success-100};
	--Success-200: #{$Success-200};
	--Success-300: #{$Success-300};
	--Success-400: #{$Success-400};
	--Success-500: #{$Success-500};
	--Success-600: #{$Success-600};
	--Success-700: #{$Success-700};
	--Success-800: #{$Success-800};
	--Success-900: #{$Success-900};
	--Success-950: #{$Success-950};

	--Warning-50: #{$Warning-50};
	--Warning-100: #{$Warning-100};
	--Warning-200: #{$Warning-200};
	--Warning-300: #{$Warning-300};
	--Warning-400: #{$Warning-400};
	--Warning-500: #{$Warning-500};
	--Warning-600: #{$Warning-600};
	--Warning-700: #{$Warning-700};
	--Warning-800: #{$Warning-800};
	--Warning-900: #{$Warning-900};
	--Warning-950: #{$Warning-950};

	--Error-50: #{$Error-50};
	--Error-100: #{$Error-100};
	--Error-200: #{$Error-200};
	--Error-300: #{$Error-300};
	--Error-400: #{$Error-400};
	--Error-500: #{$Error-500};
	--Error-600: #{$Error-600};
	--Error-700: #{$Error-700};
	--Error-800: #{$Error-800};
	--Error-900: #{$Error-900};
	--Error-950: #{$Error-950};
}

[theme-mode] {
	--color-generic-white: #{$color-generic-white};
	--color-generic-black: #{$color-generic-black};
}