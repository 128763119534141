.root {
	width: 484px;
	margin: auto;

	.form {
		display: flex;
		flex-direction: column;
		padding: 0 48px;
		gap: 32px;

		.fields-container {
			display: flex;
			flex-direction: column;
			gap: 16px;
		}

		.button {
			margin: 16px auto 48px auto;
		}

		.message-content {
			a {
				display: flex;
				align-items: center;
				gap: 8px;
				text-decoration: none;

				svg {
					path {
						stroke: var(--color-generic-black);
					}
				}
			}
		}
	}
}