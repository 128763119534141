.root {
    display: flex;
    justify-content: space-between;
    background: var(--Opium-200, #E9E1E2);
    width: calc(100vw - 24px);
    padding: 8px 12px;
    position: fixed;
    z-index: 1;

    .mid-container {
        display: flex;
        align-items: center;
        gap: 24px;
        cursor: pointer;

        .icon {
            width: 24px;
            height: 24px;
        }
    }

    .icon-delete {
        width: 20px;
        height: 20px;
        cursor: pointer;
    }
}