.root {
	display: flex;
	flex-direction: column;
	gap: 8px;

	.input-label {
		margin-bottom: 8px;
		text-align: left;
	}

	.radio-element {
		display: flex;
		align-items: baseline;
		gap: 8px;
		border: none;

		label {
			display: flex;
			flex-direction: column;
			gap: 4px;
			cursor: pointer;
		}

		label .text {
			color: var(--Wild-Sand-900);
		}

		&:hover label .text {
			color: var(--Wild-Sand-950);
		}

		input {
			cursor: pointer;

			&:checked + label .text {
				color: var(--Wild-Sand-950);
			}
		}

		&:focus-visible {
			outline: none;
		}
	}

	.tooltip-icon {
		cursor: help;
		margin-left: 4px;
		margin-bottom: -4px;
	}

	.errors-container {
		display: flex;
		gap: 20px;
		background-color: var(--Error-200);
		padding: 8px;
		align-items: center;

		.errors {
			display: flex;
			flex-direction: column;
			gap: 4px;
			color: var(--Error-800);
		}
	}

	&.hidden {
		visibility: hidden;
		position: absolute;
		pointer-events: none;
		width: 0;
		height: 0;
		overflow: hidden;
	}
}
