.root {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 16px;
	border-radius: 4px;
	border: 1px solid var(--Wild-Sand-400, #989898);
	width: 335px;

	.left-container {
		display: flex;
		align-items: center;
		gap: 8px;
		min-width: 200px;
	}
	.icon{
		width: 24px;
		height: 24px;
	}
	.icon-trash{
		cursor: pointer;
	}
}
