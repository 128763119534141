.root {
	.input-container {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		gap: 16px;
		position: relative;
	}
	.modal-edit-title {
		margin-bottom: 24px;
	}

	.button-container {
		display: flex;
		gap: 16px;
		width: 100%;
		justify-content: center;
		margin-top: 16px;
	}
}
