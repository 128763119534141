@import "themes/constants.module.scss";

.root {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	gap: 12px;
	width: fit-content;

	border: 1px solid;
	box-sizing: border-box;
	height: fit-content;

	background: transparent;

	white-space: nowrap;
	user-select: none;
	cursor: pointer;

	padding: 12px 24px;

	font-family: var(--font-secondary);
	font-weight: 400;
	font-size: 16px;

	svg {
		width: 20px;
		height: 20px;
		stroke: var(--color-generic-white);
	}

	&[variant="contained"] {
		color: var(--color-generic-white);
		background: var(--Wild-Sand-900, #141414);
		border-color: var(--Wild-Sand-900, #141414);

		&:hover {
			border-color: var(--Wild-Sand-950, #141414);
			background: var(--Wild-Sand-950, #292929);
		}

		&:disabled {
			opacity: 0.3;
			background: var(--Wild-Sand-900, #292929);
			pointer-events: none;
		}
	}

	&[variant="outlined"] {
		color: var(--Wild-Sand-950, #141414);
		background: var(--Generic-white, #fff);
		border: 1px solid var(--Wild-Sand-950, #141414);

		&:hover {
			background-color: var(--Wild-Sand-50, #f6f6f6);
		}

		&:disabled {
			opacity: 0.3;
			pointer-events: none;
		}

		svg {
			stroke: var(--color-generic-black);
		}
	}

	&[variant="text"] {
		color: var(--Wild-Sand-900, #141414);
		background-color: transparent;
		border: 1px solid transparent;
		border-bottom: 1px solid var(--Wild-Sand-900, #141414);
		padding: 4px 2px;

		&:hover {
			color: var(--Wild-Sand-950, #141414);
			border-bottom: 1px solid var(--Wild-Sand-950, #141414);
		}

		&:disabled {
			border: 1px solid transparent;
			opacity: 0.3;
			pointer-events: none;
		}

		svg {
			stroke: var(--color-generic-black);
		}
	}

	&[fullwidthattr="true"] {
		width: 100%;
		flex: 1;
	}

	&[touppercase="false"] {
		text-transform: inherit;
	}

	&[variant="line"] {
		color: var(--color-generic-black);
		background-color: transparent;
		border-color: transparent;
		padding: 0;
		font-weight: 400;
		font-size: 18px;
		line-height: 22px;
		text-decoration-line: underline;
	}
}
