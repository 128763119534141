.root {
    display: flex;
    align-items: center;
    width: 100%;
    border: 1px solid var(--Wild-Sand-200, #DCDCDC);
    border-radius: 4px;

    .left-side {
        padding: 18px;
        background: var(--Wild-Sand-100, #EFEFEF);

        .document-icon {
            width: 24px;
            height: 24px;
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        padding: 8px 16px;
        flex: 1;
    }

    .cross-icon {
        width: 24px;
        height: 24px;
        padding: 0 16px;
        cursor: pointer;
    }
}