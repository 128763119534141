.root {
	display: flex;
	flex-direction: column;
	height: 100vh;
	overflow: hidden;

	.content {
		height: calc(100vh - 43px);
		display: flex;

		.image-container {
			width: 40%;

			.image {
				height: 100%;
				object-fit: cover;
			}
		}

		.body {
			flex: 1;
			padding: 128px 140px;
			overflow: auto;

			.logo {
				width: 160px;
				margin-bottom: 32px;
			}
		}
	}

	.contact {
		height: 19px;
		padding: 12px;
		width: 100%;
		bottom: 0;
		text-align: center;
		background-color: var(--color-generic-black);
		color: var(--Wild-Sand-50);
	}
}
