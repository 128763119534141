.root {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 16px;
	text-decoration: none;

	border-bottom: 1px solid var(--Wild-Sand-200);

	.left {
		display: flex;
		align-items: center;
		gap: 16px;
	}

	&:hover {
		background-color: var(--Wild-Sand-100);
	}

	&:active {
		background-color: var(--Wild-Sand-200);
	}

	svg {
		width: 20px;
		height: 20px;
		stroke: var(--Wild-Sand-950);
	}
}