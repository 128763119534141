@import "themes/constants.module.scss";

@keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes fadeOut {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
	}
}

.root {

	.option-container {
		opacity: 0;
		display: flex;
		margin-top: 8px;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		animation: fadeOut 0.3s ease-in-out;
		position: absolute;
		pointer-events: none;

		&.init {
			animation: 0;
		}

		>:not(:last-child) {
			border-bottom: 1px solid var(--Wild-Sand-700, #525252);
		}

		&.opened {
			animation: fadeIn 0.3s ease-in-out;
			opacity: 1;

			.option {
				pointer-events: all;
				cursor: pointer;
			}
		}

		.option {
			display: flex;
			align-items: center;
			gap: 8px;
			width: 100%;
			padding: 16px 24px;
			background: var(--Wild-Sand-900, #292929);
			box-sizing: border-box;
			width: 320px;

			&:hover {
				background: var(--Wild-Sand-800, #3c3c3c);
			}
		}
	}
}