.spanTitle {
	display: flex;
	justify-content: space-between;
	gap: 10px;
	cursor: help;
	align-items: center;
	text-align: left;
}

.dot {
	width: 16px;
	height: 16px;
	flex-shrink: 0;
	border-radius: 64px;
}
