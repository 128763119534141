.root {
	display: flex;
	flex-direction: column;
	gap: 8px;

	.label-container {
		display: flex;
		align-items: center;
		gap: 8px;
	}

	.input-container {
		position: relative;
		width: 100%;
		.input-element {
			border: none;
			padding: 16px 16px;
			width: calc(100% - 34px);
			border: 1px solid var(--Wild-Sand-100, #efefef);
			background: var(--Wild-Sand-50, #f6f6f6);

			&.small {
				padding: 10px 16px;
			}

			&:hover {
				border: 1px solid var(--Wild-Sand-200, #dcdcdc);
				background: var(--Wild-Sand-50, #f6f6f6);
			}

			&:focus-visible {
				outline: none;
			}

			&[data-has-error="true"] {
				border-bottom: 1px solid var(--Error-500, #ef4444);

				&:focus-visible,
				&:hover {
					border: 1px solid var(--Error-500, #ef4444);
				}
			}

			&.readonly {
				cursor: not-allowed;
				color: var(--Wild-Sand-600, #f6f6f6);
			}
		}
		.icon-container {
			position: absolute;
			display: flex;
			align-items: center;
			top: 0;
			bottom: 0;
			right: 16px;
			z-index: 5;
			cursor: pointer;

			.icon {
				width: 20px;
			}
		}

		&.hidden {
			visibility: hidden;
			position: absolute;
			pointer-events: none;
			width: 0;
			height: 0;
			overflow: hidden;
		}
	}

	&.hidden {
		visibility: hidden;
		position: absolute;
		pointer-events: none;
		width: 0;
		height: 0;
		overflow: hidden;
	}

	.errors-container {
		display: flex;
		gap: 20px;
		background-color: var(--Error-200);
		padding: 8px;
		align-items: center;

		.errors {
			display: flex;
			flex-direction: column;
			gap: 4px;
			color: var(--Error-800);
		}
	}
}
