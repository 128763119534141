@import "themes/constants.module.scss";
@import "themes/modes.scss";

.root {
	display: flex;
	position: relative;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	border: 1px solid var(--Wild-Sand-100, #efefef);
	background-color: var(--Wild-Sand-50, #f6f6f6);
	box-sizing: border-box;

	.input-element {
		padding: 10.5px 16px;
		flex: 1;
		border: 0;
		background-color: transparent;

		&:focus,
		input:focus {
			outline: none;
		}

		&::placeholder {
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}
	}

	.icon {
		position: absolute;
		right: 10px;
		width: 24px;
		height: 24px;
		pointer-events: none;
		path {
			stroke: var(--Wild-Sand-600, #bfbfbf);
		}
	}
}
