.root {
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;

	.backdrop {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		overflow: hidden;
	}

	.modal {
		position: fixed;
		max-width: 468px;
		max-height: 85vh;
		background: var(--Generic-white, #fff);
		padding: 32px 104px;
		text-align: center;
		overflow-y: scroll;

		&.fullwidth {
			min-width: 85vw;
		}

		&.fullscreen {
			min-width: 85vw;
			padding: 0;
		}
	}
}
