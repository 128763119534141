@import "themes/constants.module.scss";
@import "themes/modes.scss";

.root {
	display: flex;
	align-items: center;
	border-bottom: 1px solid var(--Wild-Sand-100, #efefef);
	padding: 8px 0;

	.element {
		flex: 1;
	}

	.label-container {
		flex:1;
		display: flex;
		align-items: center;
		gap: 16px;
	}
}
