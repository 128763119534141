.root {
	display: flex;
	flex-direction: column;
	gap: 8px;

	input[type="number"]::-webkit-inner-spin-button,
	input[type="number"]::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	.input-group {
		display: flex;
		gap: 8px;
		.input {
			text-align: center;
			width: 12px;
			border: none;
			padding: 20px 16px;
			border: 1px solid var(--Wild-Sand-100, #efefef);
			background: var(--Wild-Sand-50, #f6f6f6);

			&:hover {
				border: 1px solid var(--Wild-Sand-200, #dcdcdc);
				background: var(--Wild-Sand-50, #f6f6f6);
			}

			&:focus-visible {
				outline: none;
			}

			&[data-has-error="true"] {
				border-bottom: 1px solid var(--Error-500, #ef4444);

				&:focus-visible,
				&:hover {
					border: 1px solid var(--Error-500, #ef4444);
				}
			}

			&::placeholder {
				text-align: center;
			}
		}
	}

	.errors-container {
		display: flex;
		gap: 20px;
		background-color: var(--Error-200);
		padding: 8px;
		align-items: center;
		.errors {
			display: flex;
			flex-direction: column;
			gap: 4px;
			color: var(--Error-800);
		}
	}
}
