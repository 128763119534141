.root {
	display: flex;
	flex-direction: column;

	.title {
		margin-bottom: 16px;
	}

	.subtitle {
		margin-bottom: 40px;
	}

	.container {
		padding: 32px 0;
		max-width: 335px;

		.button {
			margin-top: 40px;
			width: 100%;
		}

		.forgot-password {
			margin-top: 24px;
		}

		.cancel-button {
			margin-top: 8px;
			width: 100%;
		}
	}
}
