@import "themes/constants.module.scss";
@import "themes/modes.scss";

.root {
	.header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 24px;

		.input-container {
			width: 300px;
			cursor: text;
		}
	}
}
