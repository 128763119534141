.root {
	.header {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.button-container {
			display: flex;
			gap: 16px;
		}
	}

	.pdf-container {
		margin: auto;
		max-width: 900px;
		width: 100%;
		padding: 40px;
		height: 900px;
	}
}
