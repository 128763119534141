.root {
	.form {
		display: flex;
		flex-direction: column;
		gap: 32px;
		
		section {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			gap: 32px 76px;
		}
	}
}
