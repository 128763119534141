.root {
	display: flex;
	flex-direction: column;
	gap: 32px;

	.top-separator {
		position: static;
		top: 0;
		height: 8px;
		width: 100%;
		background: var(--Generic-Black, #000);
	}

	.top {
		padding: 32px 140px;
		display: grid;
		grid-template-columns: 1fr 1.25fr;
		position: relative;
		.stepper {
			position: absolute;
			display: flex;
			width: 100%;
			justify-content: center;
			top: 32px;
		}
	}

	.title {
		text-align: center;
	}
}
