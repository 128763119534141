.root {
	.header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 24px 0;
	}

	.civility-radio-input {
		margin: 24px 0;
	}

	.submit-button {
		margin-top: 24px;
	}

	.button-container {
		display: flex;
		gap: 8px;

		.submit-button {
			margin-top: 24px;
		}
	}

	.message-content {
		margin-top: 16px;

		a {
			display: flex;
			align-items: center;
			gap: 8px;
			text-decoration: none;

			svg {
				path {
					stroke: var(--color-generic-black);
				}
			}
		}
	}
}