.root {
	display: flex;
	gap: 16px;
	padding: 8px;
	border-radius: 8px;

	svg {
		width: 20px;
		height: 20px;
		min-width: 20px;
		min-height: 20px;
	}

	.content {
		display: flex;
		flex-direction: column;
		gap: 8px;
	}

	&.info {
		background-color: var(--Opium-200);

		.text {
			color: var(--Opium-900);
		}
		svg {
			path {
				stroke: var(--Opium-900);
			}
		}
	}

	&.warning {
		background-color: var(--Warning-100);

		.text {
			color: var(--Warning-900);
		}
		svg {
			path {
				stroke: var(--Warning-900);
			}
		}
	}
}
