@import "themes/constants.module.scss";
@import "themes/modes.scss";

.root {
	display: grid;
	grid-template-columns: 2fr 3fr 0.5fr;
	align-items: center;
	border-bottom: 1px solid var(--Wild-Sand-100, #efefef);
	padding: 8px 0;
	gap: 16px;

	.element {
		max-width: 435px;
		overflow: hidden;
	}

	svg {
		justify-self: flex-end;
		cursor: pointer;
	}
}
