.root {
	display: flex;
	flex-direction: column;
	gap: 32px;

	.button-container {
		display: flex;
		justify-content: center;
		gap: 24px;
	}

	.sign-container {
		width: fit-content;
		height: 128px;
		border: 1px solid var(--Wild-Sand-100, #EFEFEF);
		background-color: var(--Wild-Sand-100, #EFEFEF);
		margin: auto;
	}

	.trash-icon {
		width: 20px;
		height: 20px;
		stroke: var(--Error-600);
		margin: auto;
		cursor: pointer;
		padding: 12px 24px;
		border-radius: 100px;
		background: var(--Wild-Sand-50, #F6F6F6);
	}
}