@import "themes/constants.module.scss";
@import "themes/modes.scss";

.root {
	color: var(--Wild-Sand-950, #141414);
	margin: 0;
	padding: 0;
	display: block;
	white-space: pre-line;

	&.display_large {
		font-family: var(--font-primary);
		font-size: 52px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}

	&.display_medium {
		font-family: var(--font-primary);
		font-size: 44px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}

	&.h1 {
		font-family: var(--font-primary);
		font-size: 40px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}

	&.h2 {
		font-family: var(--font-secondary);
		font-size: 24px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
	}

	&.p_large {
		font-family: var(--font-secondary);
		font-size: 18px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}

	&.p_large_bold {
		font-family: var(--font-secondary);
		font-size: 18px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
	}

	&.p_medium {
		font-family: var(--font-secondary);
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}

	&.p_medium_bold {
		font-family: var(--font-secondary);
		font-size: 16px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
	}

	&.p_small {
		font-family: var(--font-secondary);
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}

	&.p_small_bold {
		font-family: var(--font-secondary);
		font-size: 14px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
	}

	&.caption {
		font-family: var(--font-secondary);
		font-size: 10px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}

	&.caption_bold {
		font-family: var(--font-secondary);
		font-size: 10px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
	}

	&.black {
		color: var(--color-generic-black);
	}

	&.Wild-Sand-950 {
		color: var(--Wild-Sand-950);
	}

	&.Wild-Sand-900 {
		color: var(--Wild-Sand-900);
	}

	&.Wild-Sand-700 {
		color: var(--Wild-Sand-700);
	}

	&.Wild-Sand-800 {
		color: var(--Wild-Sand-800);
	}

	&.Wild-Sand-500 {
		color: var(--Wild-Sand-500);
	}

	&.Wild-Sand-400 {
		color: var(--Wild-Sand-400);
	}

	&.Wild-Sand-600 {
		color: var(--Wild-Sand-600);
	}

	&.Error-500 {
		color: var(--Error-500);
	}

	&.Error-800 {
		color: var(--Error-800);
	}

	&.Wild-Sand-50 {
		color: var(--Wild-Sand-50);
	}

	&.Warning-600 {
		color: var(--Warning-600);
	}

	&.Warning-800 {
		color: var(--Warning-800);
	}

	&.Success-800 {
		color: var(--Success-800);
	}
}
