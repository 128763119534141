
.message-content {
	margin-top: 16px;

	a {
		display: flex;
		align-items: center;
		gap: 8px;
		text-decoration: none;

		svg {
			path {
				stroke: var(--color-generic-black);
			}
		}
	}
}